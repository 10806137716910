import { FaMapMarkerAlt } from 'react-icons/fa';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from '../responsive';
import {
  BsInstagram,
  BsGithub,
  BsTwitter,
  BsLine,
  BsDot
} from "react-icons/bs";
import { Col } from 'react-bootstrap';
import Skills from '../component/skill';
import img1 from '../images/application.png';
import img2 from '../images/security.png';
import img3 from '../images/api.png';
import img4 from '../images/cryptocurrency.png';
import Visitor from '../component/visitor';
const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const isTablet = useMediaQuery({ maxWidth: DeviceSize.tablet });
  const isNodesk = isMobile && isTablet;
  const thisYear = new Date().getFullYear()
  const skilled = [
    { app: 'Line', description: ' Elevate customer interactions with AI-powered Line bots for personalized messaging and engagement.' },
    { app: 'WhatsApp', description: 'Boost business communication with efficient AI-driven WhatsApp bots' },
    { app: 'Telegram', description: 'AI-infused Telegram bots for diverse functionalities and enhanced user experiences.' },
    { app: 'Discord', description: 'Discord bots with AI for server management and entertainment.' },
    { app: 'WeChat', description: 'AI-enabled WeChat bots for personalized business interactions.' },
    { app: 'React/Next', description: 'Craft modern web apps with React and Next.js for exceptional user experiences.' },
    { app: 'Node/Express.js', description: 'Build scalable server apps with Node.js and Express.js for high performance.' },
    { app: 'Python/Flask', description: 'Create scalable server apps with dynamic Python Language and Flask for innovation' },
    { app: 'Dart', description: 'Proficient in Dart for Flutter app development.' },
    { app: 'Flutter', description: 'Cross-platform apps with Flutter for consistent user experiences.' },
  ];

  function createStars(type, quantity) {
    for (let i = 0; i < quantity; i++) {
      var star = document.createElement('div');
      star.classList.add('star', `type-${type}`);
      star.style.left = `${randomNumber(1, 99)}%`;
      star.style.bottom = `${randomNumber(1, 99)}%`;
      star.style.animationDuration = `${randomNumber(10, 200)}s`;
      document.body.appendChild(star);
    }
  }
  function randomNumber(min, max) {
    return Math.floor(Math.random() * max) + min;
  }
  createStars(1, 4);
  createStars(2, 2);
  createStars(3, 3);

  return (
    <div className="main-page">
      <div className="home-page">
        <div className="first-box">
        </div>
        <div className="avatar-box">
          <div className="avatar-cyrcle">
            <img className="rounded-full h-full" src="https://i.pinimg.com/564x/7f/e6/f3/7fe6f3902403f51c3f31c066e1c5a455.jpg" alt="asking" />
          </div>
        </div>
        <div className="my-[20px] flex items-center center jutify-center">
          <div className="text-lg text-name uppercase text-center mx-auto">
            <span className="text-with-line"></span>Asking Ang<span className="text-with-line"></span>
          </div>
        </div>
        <div className="justify-center items-center flex text-center mb-[10px] text-sm text-white text-custom">
          #The biggest battle you'll ever have is with yourself<br />#Oh Dear.. this guy has fallen several times
          <br />#No hope! He is a walking dead
        </div>
        <div className="justify-center items-center flex text-center mb-[10px] text-sm text-white text-custom">
          <FaMapMarkerAlt className='w-[15px] h-[15px] mx-[4px]' /> Surabaya , Indonesia
        </div>
        <div className='social-media-box flex mt-[80px]'>
          <a href='https://github.com/goodop'>
            <div className='flex justify-center items-center sosmed-box bg-purple-900 mx-[15px] hover:bg-fuchsia-900 active:bg-fuchsia-900 hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
              <BsGithub className='w-[25px] h-[25px] text-white' />
            </div>
          </a>
          <a href='https://twitter.com/0xangx'>
            <div className='flex justify-center items-center sosmed-box bg-purple-900 mx-[15px] hover:bg-fuchsia-900 active:bg-fuchsia-900 hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
              <BsTwitter className='w-[25px] h-[25px] text-white' />
            </div>
          </a>
          <a href='line://ti/p/~asking.ang'>
            <div className='flex justify-center items-center sosmed-box bg-purple-900 mx-[15px] hover:bg-fuchsia-900 active:bg-fuchsia-900 hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
              <BsLine className='w-[25px] h-[25px] text-white' />
            </div>
          </a>
          <a href='https://bit.ly/asking-ang'>
            <div className='flex justify-center items-center sosmed-box bg-purple-900 mx-[15px] hover:bg-fuchsia-900 active:bg-fuchsia-900 hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
              <BsInstagram className='w-[25px] h-[25px] text-white' />
            </div>
          </a>
        </div>
        <div className='relative'>
          <div className="stars"></div>
          <div className="type-1"></div>
          <div className="type-2"></div>
          <div className="type-3"></div>
        </div>
        <div className='bg-divider' />
        <div className='divider' />
        <div className='bg-divider2' />
        <div className='h-screen-1/2 bg-[#0f0018] skill-box text-white container '>
          <div className='skill-title'>
            <div className="title-container mx-[20px] md:mx-[20px] lg:mx-[110px]">
              {/* <div className="title-line short-line"></div> */}
              <div className="title-text uppercase -ml-[8px] md:ml-0 lg:ml-0">PROGRAMMING SKill</div>
              <div className="title-line long-line"></div>
            </div>
            <div className='text-md text-white capitalize text-custom mx-[20px] md:mx-[20px] lg:mx-[110px] md:p-3'>
              I'm a skilled programmer specializing in bot development for messaging apps like Line, WhatsApp, Telegram, Discord, WeChat, and more. Additionally, I create websites using various frameworks.
            </div>
          </div>
          <div className="sm:block md:block lg:flex mx-[20px] md:mx-[60px] lg:mx-[110px] mt-2">

            <div className="sm:mb-4 md:w-full lg:w-1/2 md:mr-4 lg:mr-4 flex justify-center">
              <div className="bg-transparent p-4">
                <ul className='block list-disc'>
                  {
                    skilled.map((item, index) => (
                      <li className='text-base my-2' key={index}>
                        <span className='font-bold'>
                          {item.app}: </span>
                        {item.description}
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>


            <div className="w-full md:w-auto lg:w-1/2">
              <div className="bg-transparent mx-6 my-4">
                <Skills />
              </div>
            </div>
          </div>

        </div>
        <div className={`${isNodesk ? "block" : "hidden"} service-box relative mt-16`}>
          <div className='service-title'>
            {/* <div className='title-service-text mx-[20px]'>
              Service and Work
            </div>
            <div className='text-md mx-[20px] text-white capitalize text-custom'>
              My Work and What i can do ?
            </div> */}
            <div className="title-container mx-[20px] md:mx-[0px] lg:mx-[110px]">
              {/* <div className="title-line short-line"></div> */}
              <div className="title-text uppercase ">MY service</div>
              <div className="title-line long-line"></div>
            </div>
            <div className='text-md text-white capitalize text-custom mx-[30px] md:mx-[0px] lg:mx-[110px]'>
              Our suite of services spans a wide spectrum of digital solutions, meticulously designed to ensure that every facet of your online presence and operational efficiency is finely tuned and maximized to its fullest potential.
            </div>
          </div>
          <div className='circle-1x' />
          <div className='circle-2x' />
          <div className='circle-3x' />
          <div className='mx-[20px] mb-[20px]'>
            <div className='flex justify-center items-center relative'>
              <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                <div className='title-inside mx-[20px] my-[20px]'>
                  <span className='mx-[5px] website' />Website
                </div>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <div className='h-24 w-24 bg-fuchsia-900 opacity-20 rounded-full flex justify-center items-center'>
                    <img src={img1} alt='website' className='w-16 h-16' />
                  </div>
                </div>
                <div className='my-[5px] mx-[25px] block capitalize'>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-purple-500 text-md ' /> Official and Company Website
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-purple-500 text-md ' /> News Website and Blog
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-purple-500 text-md' /> Forum and Community Blog
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-purple-500 text-md' /> And More
                  </div>
                </div>
              </div>
            </div>

            <div className='flex justify-center items-center relative'>
              <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                <div className='title-inside mx-[20px] my-[20px]'>
                  <span className='mx-[5px] bots' />Social Bot
                </div>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <div className='h-24 w-24 bg-red-500 opacity-20 rounded-full flex justify-center items-center'>
                    <img src={img2} alt='bot' className='w-16 h-16' />
                  </div>
                </div>
                <div className='my-[5px] mx-[20px] block capitalize'>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-red-500 text-md' /> Line Bots | Selfbot | Protect | Public
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-red-500 text-md' /> Whatsapp Bots | Selfbot | Service
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-red-500 text-md' /> Telegram Bots | Service | Crypto Bots
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-red-500 text-md' /> And More
                  </div>
                </div>
              </div>
            </div>

            <div className='flex justify-center items-center relative'>
              <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                <div className='title-inside mx-[20px] my-[20px]'>
                  <span className='mx-[5px] api' />WEB API
                </div>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <div className='h-24 w-24 bg-teal-500 opacity-20 rounded-full flex justify-center items-center'>
                    <img src={img3} alt='api' className='w-16 h-16' />
                  </div>
                </div>
                <div className='my-[5px] mx-[20px] block '>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-teal-500 text-md' /> Web API Intergration
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-teal-500 text-md' /> Rest API Deployment
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-teal-500 text-md' /> And More
                  </div>
                </div>
              </div>
            </div>

            <div className='flex justify-center items-center relative'>
              <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                <div className='title-inside mx-[20px] my-[20px]'>
                  <span className='mx-[5px] crypto' />Crypto Page
                </div>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <div className='h-24 w-24 bg-yellow-500 opacity-20 rounded-full flex justify-center items-center'>
                    <img src={img4} alt='crypto' className='w-16 h-16' />
                  </div>
                </div>
                <div className='my-[5px] mx-[20px] block capitalize'>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-yellow-500 text-md' /> Landing Page
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-yellow-500 text-md' /> Presale & Seedsale Page
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-yellow-500 text-md' /> Launchpad Page
                  </div>
                  <div className='text-md text-white flex text-custom'>
                    <BsDot className='my-auto text-yellow-500 text-md' /> And More
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${isNodesk ? "hidden" : "block"} container mt-16`}>

          <div className='service-box relative mb-[20px]'>
            <div className='circle-1x' />
            <div className='circle-2x' />
            <div className='circle-3x' />
            <div className='mx-[20px]'>
              {/* <div className='service-title my-[10px]'>
                <div className='title-service-text mx-[90px]'>
                  Service and Work
                </div>
                <div className='text-md mx-[90px] text-white capitalize text-custom'>
                  My Work and What i can do ?
                </div>
              </div> */}
              <div className="title-container mx-[20px] md:mx-[0px] lg:mx-[90px]">
                <div className="title-text uppercase">MY service</div>
                <div className="title-line long-line"></div>
              </div>
              <div className='text-md text-white capitalize text-custom mx-[20px] md:mx-[0px] lg:mx-[90px] md:p-3'>
                Our suite of services spans a wide spectrum of digital solutions, meticulously designed to ensure that every facet of your online presence and operational efficiency is finely tuned and maximized to its fullest potential.
              </div>
              <Col xs={12} className='flex mt-[25px] '>
                <Col xs={6}>
                  <div className='flex justify-center items-center relative'>
                    <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                      <div className='title-inside mx-[20px] my-[20px]'>
                        <span className='mx-[5px] website' />Website
                      </div>
                      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='h-24 w-24 bg-fuchsia-900 opacity-20 rounded-full flex justify-center items-center'>
                          <img src={img1} alt='website' className='w-16 h-16' />
                        </div>
                      </div>
                      <div className='my-[5px] mx-[25px] block capitalize'>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-purple-500 text-md ' /> Official and Company Website
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-purple-500 text-md' /> News Website and Blog
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-purple-500 text-md' /> Forum and Community Blog
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-purple-500 text-md' /> And More
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className='flex justify-center items-center relative'>
                    <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                      <div className='title-inside mx-[20px] my-[20px]'>
                        <span className='mx-[5px] bots' />Social Bot
                      </div>
                      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='h-24 w-24 bg-red-900 opacity-20 rounded-full flex justify-center items-center'>
                          <img src={img2} alt='bots' className='w-16 h-16' />
                        </div>
                      </div>
                      <div className='my-[5px] mx-[20px] block capitalize'>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-red-500 text-md' /> Line Bots | Selfbot | Protect | Public
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-red-500 text-md' /> Whatsapp Bots | Selfbot | Service
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-red-500 text-md' /> Telegram Bots | Service | Crypto Bots
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-red-500 text-md' /> And More
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>

              <Col xs={12} className='flex'>
                <Col xs={6}>
                  <div className='flex justify-center items-center relative'>
                    <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                      <div className='title-inside mx-[20px] my-[20px]'>
                        <span className='mx-[5px] api' />WEB API
                      </div>
                      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='h-24 w-24 bg-teal-500 opacity-20 rounded-full flex justify-center items-center'>
                          <img src={img3} alt='api' className='w-16 h-16' />
                        </div>
                      </div>
                      <div className='my-[5px] mx-[20px] block '>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-teal-500 text-md' /> Web API Intergration
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-teal-500 text-md' /> Rest API Deployment
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-teal-500 text-md' /> And More
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className='flex justify-center items-center relative'>
                    <div className='box-service-detail my-[20px] hover:shadow-lg hover:shadow-fuchsia-900 transform transition duration-500 hover:scale-110'>
                      <div className='title-inside mx-[20px] my-[20px]'>
                        <span className='mx-[5px] crypto' />Crypto Page
                      </div>
                      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='h-24 w-24 bg-amber-500 opacity-20 rounded-full flex justify-center items-center'>
                          <img src={img4} alt='cryptopage' className='w-16 h-16' />
                        </div>
                      </div>
                      <div className='my-[5px] mx-[20px] block capitalize'>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-yellow-500 text-md' /> Landing Page
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-yellow-500 text-md' /> Presale & Seedsale Page
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-yellow-500 text-md' /> Launchpad Page
                        </div>
                        <div className='text-md text-white flex text-custom'>
                          <BsDot className='my-auto text-yellow-500 text-md' /> And More
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>
            </div>
          </div>

        </div>
        <Visitor />
        <div className='footer-box flex items-center'>
          <div className='text-footer'>
            <div className='copyright capitalize'>
              copyright@{thisYear} - Asking Ang
            </div>
            <div className='copyright-reserved capitalize'>
              All Right Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Home;