// import './App.sass';
import './App.css';
import React from 'react';
import Router from './router';
const Application = () => {
  return (
    <div>
      <Router />
    </div>
  )
}
export default Application;