import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CountryFlag from './countryFlag';
import { BsEyeFill } from 'react-icons/bs';
import { MdDevices } from 'react-icons/md';
function Visitor() {
    const [visitorData, setVisitorData] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);
    const [thisIpLook, setLookIP] = useState('')

    useEffect(() => {
        const userAgent = navigator.userAgent;
        axios.get('https://api.ipify.org?format=json')
            .then((response) => {
                const ipAddress = response.data.ip;
                setLookIP(ipAddress);

                axios.get('https://execross.pw/api/v2/check-proxy')
                    .then((proxyResponse) => {
                        const isProxied = proxyResponse.data.isProxied;

                        const newVisitorData = {
                            userAgent: userAgent,
                            ipAddress: ipAddress,
                            proxyStatus: isProxied,
                        };

                        axios.post('https://execross.pw/api/v2/save-visitor', newVisitorData)
                            .then((response) => {
                                setVisitorData(response);
                                console.log('Data posted successfully');
                            })
                            .catch((error) => {
                                console.error('Error posting data:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error checking proxy status:', error);
                    });
            })
            .catch((error) => {
                console.error('Error fetching IP address:', error);
            });
    }, []);

    const toggleItem = (index) => {
        if (expandedItem === index) {
            setExpandedItem(null);
        } else {
            setExpandedItem(index);
        }
    };
    const datascheck = visitorData.data
    const datas = datascheck ? visitorData.data.result.data : false
    const visiting = datascheck ? visitorData.data.result.total_visitor : false
    return (
        <div className='h-screen-1/2 bg-transparent skill-box text-white container mt-16 relative mb-8 z-40'>
            <div className='skill-title !bg-transparent'>
                <div className="title-container mx-[20px] md:mx-[12px] lg:mx-[110px]">
                    {/* <div className="title-line short-line"></div> */}
                    <div className="title-text uppercase -ml-[8px] md:ml-0 lg:ml-0">Visitor Statistics</div>
                    <div className="title-line long-line"></div>
                </div>
                <div className='text-md text-white capitalize text-custom mx-[20px] md:mx-[10px] lg:mx-[110px] md:p-3'>
                    In-Depth Analysis of Visitor Information, Covering IP Addresses and Regional Data, Subject to Network Location Variation.
                </div>
                <div className='mx-[15px] md:mx-[12px] lg:mx-[110px]'>
                    {
                        datas ?
                            <div className='text-lg font-bold m-2 flex'>
                                Visitors: {visiting} <BsEyeFill className='mx-2 my-auto' />
                            </div>
                            : <div className='text-lg font-bold my-2 flex'>
                                Visitors: Counting..
                            </div>
                    }
                </div>

            </div>
            <div className="sm:block md:flex lg:flex mx-[10px] md:mx-[12px] lg:mx-[110px] mt-2">

                <div className="sm:mb-4 md:w-full lg:w-1/2 md:mr-4 lg:mr-4 flex justify-start h-auto max-h-72 overflow-auto mx-2 md:mx-0 lg:mx-0 my-6" id='scroll-slide'>
                    <div className='text-white  mx-[5px]'>
                        <div className='text-lg font-bold'>
                            Last Visitors:
                        </div>
                        <ul className="visitor-list">
                            {datas
                                ? datas
                                    .sort((a, b) => new Date(b.last_visit) - new Date(a.last_visit)) // Sort by last_visit in descending order
                                    .map((visitor, index) => (
                                        <li key={index} className={`visitor-item ${expandedItem === index ? 'expanded' : ''}`}>
                                            <div className='text-base' onClick={() => toggleItem(index)}>
                                                <div className='flex'>
                                                    <p className='font-semibold'>IP Address: {visitor.ipAddress} <CountryFlag countryCode={visitor.country} /> <span className='text-blue-800'>View</span></p>
                                                </div>
                                                {expandedItem === index && (
                                                    <div className='box-device rounded-md my-2 transition duration-500 ease-in-out'>
                                                        <div className='p-2 text-sm'>
                                                            <p>Visited: {visitor.visited}</p>
                                                            <p>Last Visit: {visitor.last_visit}</p>
                                                            <p>Region: {visitor.city}, {visitor.region}</p>
                                                            <p>ISP: {visitor.isp}</p>

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))
                                : <div>loading</div>}
                        </ul>

                    </div>
                </div>


                <div className="w-full md:w-auto lg:w-1/2">
                    <div className='font-bold text-lg my-2 md:mx-6 lg:mx-6 mx-2'>
                        <div className='flex'>
                            <MdDevices className='mx-2 my-auto' /> Device Info:
                        </div>
                    </div>
                    <div className="bg-transparent mx-6 my-2">
                        <div className='box-device rounded-md h-auto '>
                            <div className='p-2'>
                                <ul className="visitor-list">
                                    {datas ? datas.map((visitor, index) => (
                                        visitor.ipAddress === thisIpLook &&
                                        <li key={index}>
                                            <div className='text-sm'>
                                                <div className='flex'>
                                                    <p className='font-semibold p-2'>IP: {visitor.ipAddress} <CountryFlag countryCode={visitor.country} /></p>
                                                </div>
                                                <div className='p-2 text-sm block'>

                                                    <div className='flex'>
                                                        <p className='font-semibold mr-2'>Visited: </p>
                                                        <p>{visitor.visited}</p>
                                                    </div>

                                                    <div className='flex'>
                                                        <p className='font-semibold mr-2'>Last Visit: </p>
                                                        <p> {visitor.last_visit}</p>
                                                    </div>



                                                    {visitor.proxyStatus !== undefined ? (
                                                        <div className='flex'>
                                                            <p className='font-semibold mr-2'>Proxy:</p>
                                                            <p>{visitor.proxyStatus ? 'Yes' : 'None'}</p>
                                                        </div>
                                                    ) : (
                                                        <div className='flex'>
                                                            <p className='font-semibold mr-2'>Proxy:</p>
                                                            <p>Loading...</p>
                                                        </div>
                                                    )}



                                                    <div className='flex'>
                                                        <p className='font-semibold mr-2'>ISP: </p>
                                                        <p>{visitor.isp}</p>
                                                    </div>

                                                    <div className='flex'>
                                                        <p className='font-semibold mr-2'>Region: </p>
                                                        <p>{visitor.city}, {visitor.region}</p>
                                                    </div>

                                                    <div className='flex'>
                                                        <p className='font-semibold mr-2'>UA:</p>
                                                        <p>{visitor.userAgent}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </li>
                                    )) : <div>loading</div>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Visitor;


