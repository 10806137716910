import React, { useEffect } from 'react';

const Skills = () => {
    const lang = {
        html: '95%',
        css: '94%',
        javascript: '85%',
        react: '85%',
        python: '95%',
        golang: '78%'
    };

    const multiply = 4;

    useEffect(() => {
        Object.entries(lang).forEach(([language, pourcent], index) => {
            const delay = 700 * (index + multiply);
            setTimeout(() => {
                document.getElementById(`${language}-pourcent`).textContent = pourcent;
            }, delay);
        });    // eslint-disable-next-line
    }, []);

    return (
        <ul className="skills-bar-container">
            <li>
                <div className="progressbar-title">
                    <h3>HTML5</h3>
                    <span className="percent" id="html-pourcent"></span>
                </div>
                <div className="bar-container">
                    <span className="progressbar progressred" id="progress-html"></span>
                </div>
            </li>
            <li>
                <div className="progressbar-title">
                    <h3>CSS </h3>
                    <span className="percent" id="css-pourcent"></span>
                </div>
                <div className="bar-container">
                    <span className="progressbar progressblue" id="progress-css"></span>
                </div>
            </li>
            <li>
                <div className="progressbar-title">
                    <h3>React</h3>
                    <span className="percent" id="react-pourcent"></span>
                </div>
                <div className="bar-container">
                    <span className="progressbar progressorange" id="progress-react"></span>
                </div>
            </li>
            <li>
                <div className="progressbar-title">
                    <h3>Python</h3>
                    <span className="percent" id="python-pourcent"></span>
                </div>
                <div className="bar-container">
                    <span className="progressbar progressgreen" id="progress-python"></span>
                </div>
            </li>
            <li>
                <div className="progressbar-title">
                    <h3>Javascript</h3>
                    <span className="percent" id="javascript-pourcent"></span>
                </div>
                <div className="bar-container">
                    <span className="progressbar progresspurple" id="progress-javascript"></span>
                </div>
            </li>
            <li>
                <div className="progressbar-title">
                    <h3>Golang</h3>
                    <span className="percent" id="golang-pourcent"></span>
                </div>
                <div className="bar-container">
                    <span className="progressbar progressteal" id="progress-golang"></span>
                </div>
            </li>
        </ul>
    );
};

export default Skills;
