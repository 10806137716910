import React from 'react';
import 'flag-icons/css/flag-icons.css';
function CountryFlag({ countryCode }) {
    if (countryCode) {
        const flagClass = `fi fi-${countryCode.toLowerCase()} mx-2`;
        return (
            <><span className={flagClass}></span></>
        );
    } else {
        const flagClass = `fi fi-jp mx-2`;
        return (
            <><span className={flagClass}></span></>
        );
    }

}

export default CountryFlag;